@import url('https://fonts.googleapis.com/css?family=Besley:bold:regular');
@import url('https://fonts.googleapis.com/css?family=Alegreya Sans:thin:light:regular:medium');

div {
    border-radius: 0px;
    display: grid;
}
img {
    align-items: center;
    display: grid;
}

h1, h2, h3, h4, p {
    font-family: 'Besley', serif;
}

html, body, nav {
    padding: 0px;
    margin: 0px;
    display: grid;
}

.nav-bar {
    grid-auto-flow: column;
    border-bottom: 5px solid #b0341a;
    grid-template-columns: 64px 10fr min-content min-content max-content;
    align-items: center;
    grid-column-gap: 30px;
    padding: 10px;
    height: min-content;
}

.nav-bar a {
   font-family: 'Besley', serif;
   color: #1f0100;
   text-decoration: none;
   font-weight: 700;
   font-size: 2.5em;
}

.nav-link.active {
    color: #b0341a;
}


.nav-bar a:hover {
    color: #b0341a;
}

.nav-bar a:active {
    filter: brightness(75%);
}


@media only screen and (max-width: 300px) {
    .nav-bar a:not(:first-child) {display: none;}
    .nav-bar a.icon {
      float: right;
      display: block;
    }

    .nav-bar.responsive {position: relative;}
    .nav-bar.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .nav-bar.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}

.search {
    grid-auto-flow: column;
}